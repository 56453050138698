import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ILoginCommunicate, IUserCredential } from '../../../shared/models';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  isRequesting = false;

  private subscription: Subscription;

  constructor(private fb: FormBuilder,
              private auth: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.subscription = this.auth.loginReq.subscribe((loginData: ILoginCommunicate) => {
      if (loginData.isLoggedIn) {
        this.router.navigate(['/dashboard']);
      }
      this.isRequesting = loginData.isRequesting;
    });
    this.initFormGroup();
  }

  private initFormGroup(formData?: IUserCredential) {
    this.loginForm = this.fb.group({
      username: [formData && formData.username || null, Validators.required],
      password: [formData && formData.password || null, Validators.required],
      remember: [false],
    });
  }

  async onFormSubmit() {
    Object.keys(this.loginForm.controls).forEach(key => {
      this.loginForm.controls[key].markAsDirty();
      this.loginForm.controls[key].updateValueAndValidity();
    });
    if (this.loginForm.valid) {
      await this.auth.login(this.loginForm.getRawValue(), this.activatedRoute.snapshot.queryParams.returnUrl);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
