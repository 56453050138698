import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-wisx-detail-actions',
  templateUrl: './wisx-detail-actions.component.html',
  styleUrls: ['./wisx-detail-actions.component.scss']
})
export class WisxDetailActionsComponent implements OnInit {

  @Input() extraAction: TemplateRef<any>;
  @Input() customContent: TemplateRef<any>;

  @Output() backAction = new EventEmitter<void>();
  @Output() deleteAction = new EventEmitter<void>();
  @Output() copyAction = new EventEmitter<void>();
  @Output() editAction = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
