import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICommonListItem } from '../../../../shared/models';

@Component({
  selector: 'app-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss']
})
export class TableActionsComponent implements OnInit {

  @Input() switchItems: ICommonListItem[] = [
    {
      value: 'manage',
      label: 'COMMON.MANAGE',
    },
    {
      value: 'unmanage',
      label: 'COMMON.UNMANAGE',
    },
  ];
  @Input() exportItemTypes: ICommonListItem[] = [
    {
      value: 'json',
      label: 'EXPORT.JSON',
    },
    {
      value: 'xml',
      label: 'EXPORT.XML',
    },
    {
      value: 'csv',
      label: 'EXPORT.CSV',
    },
    {
      value: 'txt',
      label: 'EXPORT.TXT',
    },
    {
      value: 'sql',
      label: 'EXPORT.SQL',
    },
    {
      value: 'excel',
      label: 'EXPORT.EXCEL',
    },
  ];
  @Input() tableColumnConfig: ICommonListItem[] = [
    {
      value: true,
      label: 'Network Type',
    }
  ];

  @Output() newAction = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
