import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { LOCAL_STORAGE_KEY } from '../../shared/constant';
import { IDashboard } from '../../shared/models';

interface IAuthInfo {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private authLocalStorage: any;

  private get authLocalStoreOnce() {
    if (!this.authLocalStorage) {
      this.authLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY.authorization);
    }
    return this.authLocalStorage;
  }

  constructor() {
  }

  public setItem(name: string, data: any) {
    if (!isNullOrUndefined(data)) {
      localStorage.setItem(name, JSON.stringify(data));
    } else {
      throw Error('The object is not suitable to store!');
    }
  }

  public clearAllStorage() {
    localStorage.clear();
  }

  public clearItem(name: string) {
    localStorage.removeItem(name);
  }

  public get authorizationInfo(): IAuthInfo | undefined {
    return this.authLocalStoreOnce ? JSON.parse(this.authLocalStoreOnce) : undefined;
  }

  public get dashboardSetting(): IDashboard | undefined {
    const result = localStorage.getItem(LOCAL_STORAGE_KEY.dashSetting);
    if (!result) {
      return undefined;
    }
    return JSON.parse(result);
  }
}
