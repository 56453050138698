import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { LoginComponent } from './login/login.component';
import { UserRoutingModule } from './user-routing.module';
import { SharingModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';

@NgModule({
  declarations: [
    UserComponent,
    LoginComponent,
    ForgotPasswordComponent,
    VerifyCodeComponent
  ],
  imports: [
    CommonModule,

    UserRoutingModule,

    SharingModule,
  ]
})
export class UserModule {}
