import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';

const userRoutes: Routes = [
  {
    path: '',
    component: UserComponent,
    data: {publicRoute: true},
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: {title: 'Login'}
      },
      {
        path: 'reset-password',
        component: ForgotPasswordComponent,
        data: {title: 'Reset Password'}
      },
      {
        path: 'verify-code',
        component: VerifyCodeComponent,
        data: {title: 'Verify Code'}
      },
      {
        path: '',
        redirectTo: '/dashboard', pathMatch: 'full',
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(userRoutes),
  ],
  exports: [
    RouterModule,
  ]
})
export class UserRoutingModule {
}
