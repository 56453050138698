import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';

import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  en_US,
  NZ_I18N,
  NZ_ICONS,
  NZ_MESSAGE_CONFIG, NzButtonModule, NzCardModule, NzCheckboxModule, NzDropDownModule,
  NzGridModule,
  NzInputModule, NzLayoutModule, NzMenuModule,
  NzMessageConfig, NzSwitchModule
} from 'ng-zorro-antd';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserModule } from './modules/user/user.module';
import { AppRoutingModule } from './app-routing.module';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import {FormsModule} from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

registerLocaleData(en);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.5
};

// ICON
const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

// MESSAGE
const msgConfig: NzMessageConfig = {
  nzDuration: 3000,
  nzMaxStack: 7,
  nzPauseOnHover: true,
  nzAnimate: true,
};

// TRANSLATION CONFIG
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    MainLayoutComponent,
    UnderConstructionComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,

    UserModule,

    AppRoutingModule,

    PerfectScrollbarModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),

    NzGridModule,
    NzInputModule,
    NzCheckboxModule,
    NzLayoutModule,
    NzMenuModule,
    NzDropDownModule,
    NzCardModule,
    NzButtonModule,
    NzSwitchModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: NZ_I18N, useValue: en_US},
    {provide: NZ_ICONS, useValue: icons},
    {provide: NZ_MESSAGE_CONFIG, useValue: msgConfig},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
