import { NgModule } from '@angular/core';
import {
  NzAlertModule,
  NzButtonModule,
  NzCardModule,
  NzCheckboxModule,
  NzDatePickerModule,
  NzDividerModule,
  NzDropDownModule,
  NzFormModule,
  NzGridModule,
  NzIconModule,
  NzInputModule,
  NzLayoutModule,
  NzListModule,
  NzMenuModule,
  NzModalModule,
  NzPopoverModule,
  NzRadioModule,
  NzSelectModule, NzStepsModule,
  NzSwitchModule,
  NzTableModule,
  NzToolTipModule
} from 'ng-zorro-antd';
import { CommonModule } from '@angular/common';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnumPipe } from './pipe/enum.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { OlMapComponent } from './component/ol-map/ol-map.component';
import { CardDeviceDetailComponent } from './component/card-device-detail/card-device-detail.component';
import { GroupDevicesFormComponent } from './component/group-devices-form/group-devices-form.component';
import { WisxModalComponent } from './component/wisx-modal/wisx-modal.component';
import { SettingMenuComponent } from './component/setting-menu/setting-menu.component';
import { TableActionsComponent } from './component/table-actions/table-actions.component';
import { WisxWizardFormComponent } from './component/wisx-wizard-form/wisx-wizard-form.component';
import { WixsFormConfirmStepComponent } from './component/wixs-form-confirm-step/wixs-form-confirm-step.component';
import { WisxDetailActionsComponent } from './component/wisx-detail-actions/wisx-detail-actions.component';
import { WisxListboxComponent } from './component/wisx-listbox/wisx-listbox.component';
import { WisxCardSimpleDetailComponent } from './component/wisx-card-simple-detail/wisx-card-simple-detail.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    NzGridModule,
    NzCardModule,
    NzButtonModule,
    NzModalModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzPopoverModule,
    NzListModule,
    NzSwitchModule,
    NzDropDownModule,
    NzStepsModule,

    PerfectScrollbarModule,

    TranslateModule.forChild(),

    AngularOpenlayersModule,
  ],
  exports: [
    // CORE
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,

    // ANTD
    NzLayoutModule,
    NzMenuModule,
    NzCardModule,
    NzGridModule,
    NzTableModule,
    NzDropDownModule,
    NzMenuModule,
    NzIconModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzDividerModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzSelectModule,
    NzSwitchModule,
    NzCheckboxModule,
    NzAlertModule,
    NzRadioModule,
    NzModalModule,
    NzPopoverModule,
    NzListModule,

    // COMPONENT
    OlMapComponent,
    GroupDevicesFormComponent,
    WisxModalComponent,
    WisxWizardFormComponent,
    SettingMenuComponent,
    WixsFormConfirmStepComponent,
    TableActionsComponent,
    WisxDetailActionsComponent,
    WisxListboxComponent,
    WisxCardSimpleDetailComponent,

    EnumPipe,
  ],
  declarations: [
    EnumPipe,
    OlMapComponent,
    CardDeviceDetailComponent,
    GroupDevicesFormComponent,
    WisxModalComponent,
    SettingMenuComponent,
    TableActionsComponent,
    WisxWizardFormComponent,
    WixsFormConfirmStepComponent,
    WisxDetailActionsComponent,
    WisxListboxComponent,
    WisxCardSimpleDetailComponent,
  ],
})
export class SharingModule { }
