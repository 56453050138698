import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit {

  isError = false;
  code: number;

  constructor() { }

  ngOnInit() {
  }

}
