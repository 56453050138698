import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getGeoPoint } from './utils';
import { ICommonListItem, IDevice } from '../../../../shared/models';

declare const Geocoder: any;
declare const ol: any;

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss']
})
export class OlMapComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() center!: [number, number];
  @Input() width: string | number = '100%';
  @Input() height: string | number = 'calc(100vh - 31px)';
  @Input() zoom = 14;
  @Input() strokeWidth = 2;
  @Input() strokeColor = '#FF8A00';
  @Input() fillColor = 'rgba(0, 0, 0, 0.7)';
  @Input() haveDrawInteraction = false;
  @Input() haveDetailPopup = false;
  @Input() haveMenuPopup = false;
  @Input() haveSearchLocation = false;

  @Input('isHeatmapActive') set heatmapStatus(val) {
    this.isHeatmapActive = val;
    if (val) {
      if (!this.heatmapLayer) {
        this.heatmapLayer = new ol.layer.Heatmap({
          source: new ol.source.Vector({
            url: 'assets/earthquakes.kml',
            format: new ol.format.KML({
              extractStyles: false
            })
          }),
          weight: 'weigh',
        });
      }
      if (this.map) {
        this.map.addLayer(this.heatmapLayer);
        this.map.removeInteraction(this.draw);
      }
    } else {
      if (this.map) {
        this.map.removeLayer(this.heatmapLayer);
        this.map.removeInteraction(this.draw);
      }
    }
  }

  get heatmapStatus(): boolean {
    return this.isHeatmapActive;
  }

  @Input('markers') set markerIcons(devices: IDevice[]) {
    if (devices) {
      this.devices = devices;
      // this.addMarkers(devices);
      this.addClusterMarker(devices);
      // ADD DETAIL POPUP OVERLAY ONLY ONE TIME
      if (!this.markerHoverPopup && this.detailPopup) {
        this.markerHoverPopup = new ol.Overlay({
          element: this.detailPopup.nativeElement,
          positioning: 'bottom-center',
          offset: [0, -30],
          id: 'marker-detail',
        });
      }
    }
  }

  @Input('activeDraw') set activeDraw(value: null | 'polygon' | 'select') {
    this.selectionType = value;
    this.toggleDraw(false);
    this.toggleSelect(false);
    this.selectedMarkers = [];
    // CLEAR THE OLD SNAPS
    this.vectorSource.clear();
    // CHECK TO MAKE SURE HEATMAP IS NOT ACTIVE
    if (!this.isHeatmapActive) {
      // CHECK DRAW TYPE
      if (value === 'polygon') {
        if (this.select) {
          this.map.removeInteraction(this.select);
        }
        this.cancelGroup.emit();
        this.updateDrawType();
      }
      if (value === 'select') {
        if (this.draw) {
          this.map.removeInteraction(this.draw);
        }
        this.cancelGroup.emit();
        this.triggerSelectInteraction();
      }
      if (value && this.map) {
        this.map.removeOverlay(this.markerHoverPopup);
        this.detailPopup.nativeElement.hidden = true;
      }
    }
  }

  get activeDraw(): null | 'polygon' | 'select' {
    return this.selectionType;
  }

  @Input() set requestCancel(val) {
    if (val) {
      this.map.removeOverlay(this.mapPopup);
      this.popup.nativeElement.hidden = true;
      this.vectorSource.clear();
    }
  }

  @Output() drawComplete = new EventEmitter<any>();
  @Output() saveGroup = new EventEmitter<void>();
  @Output() cancelGroup = new EventEmitter<void>();
  @Output() searchComplete = new EventEmitter<[number, number]>();

  @ViewChild('olMap') olMap;
  @ViewChild('popup') popup;
  @ViewChild('deviceDetail') detailPopup;

  mapTypes: ICommonListItem[] = [
    {value: 'osm', label: 'Open Street Map'},
    {value: 'gsm', label: 'Google Street Map'},
    {value: 'gsl', label: 'Google Satellite'}
  ];
  selectedMapType = this.mapTypes[0].value;
  private mapLayers: any = [
    new ol.layer.Tile({
      preload: 4,
      source: new ol.source.OSM({})
    }),
    new ol.layer.Tile({
      visible: false,
      preload: 4,
      source: new ol.source.XYZ({
        url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      })
    }),
    new ol.layer.Tile({
      visible: false,
      preload: 4,
      source: new ol.source.XYZ({
        url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      })
    }),
  ];

  private selectionType: null | 'polygon' | 'select';
  private isHoverPopupOpen = false;
  private isHeatmapActive = false;
  private devices: IDevice[];
  // private currentZoomLevel: number = this.zoom;
  hoveredMarker: IDevice | any = {};
  private selectedMarkers: any = [];

  private imageCache: any = {};

  map: ol.Map;

  mapPopup: ol.Overlay;
  private markerHoverPopup: ol.Overlay;

  private heatmapLayer: ol.layer.Heatmap;
  private vectorLayer: ol.layer.Vector;
  // private markerLayer: ol.layer.Vector;
  private clusterLayer: any;

  private vectorSource = new ol.source.Vector();
  // private markerSource = new ol.source.Vector();
  private clusterSource = new ol.source.Cluster({
    distance: 40,
    source: new ol.source.Vector()
  });

  private draw: ol.interaction.Draw;
  private snap: ol.interaction.Snap;
  private select: ol.interaction.Select;

  constructor() { }

  ngOnInit() {
    this.vectorLayer = new ol.layer.Vector({
      source: this.vectorSource,
      style: new ol.style.Style({
        fill: new ol.style.Fill({
          color: this.fillColor,
        }),
        stroke: new ol.style.Stroke({
          color: this.strokeColor,
          width: this.strokeWidth,
        }),
        image: new ol.style.Circle({
          radius: 5,
          stroke: new ol.style.Stroke({
            color: this.strokeColor,
            width: this.strokeWidth,
          }),
          fill: new ol.style.Fill({
            color: this.fillColor,
          })
        }),
      })
    });
    this.clusterLayer = new ol.layer.AnimatedCluster({
      name: 'Cluster',
      source: this.clusterSource,
      animationDuration: 700,
      style: (feature) => this.getStyle(feature, this.imageCache),
    });
    this.map = new ol.Map({
      target: this.olMap.nativeElement,
      logo: false,
      controls: ol.control.defaults({attribution: false, rotate: false, zoom: false}),
      layers: [
        ...this.mapLayers,
        this.vectorLayer,
        this.clusterLayer,
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat(this.center),
        zoom: this.zoom,
        maxZoom: 19,
      }),
      loadTilesWhileAnimating: true,
    });
    this.map.render();
  }

  addClusterMarker(devices: IDevice[]) {
    const clusterMarkers = [];
    devices.forEach(device => {
      const clusterMarker = new ol.Feature({geometry: getGeoPoint(device.longitude, device.latitude)});
      clusterMarker.setId(device.name);
      clusterMarker.set('deviceStatus', device.stringStatus ? device.stringStatus.toLowerCase() : 'unknown');
      clusterMarker.set('deviceType', device.type ? device.type.toLowerCase() : 'undefined');
      clusterMarkers.push(clusterMarker);
    });
    this.clusterSource.getSource().clear();
    this.clusterSource.getSource().addFeatures(clusterMarkers);
  }

  ngAfterViewInit(): void {
    if (this.haveSearchLocation) {
      const geoCoder = new Geocoder('nominatim', {
        provider: 'osm',
        lang: 'en',
        placeholder: 'Search for ...',
        limit: 5,
        debug: false,
        autoComplete: true,
        keepOpen: true
      });
      this.map.addControl(geoCoder);
      geoCoder.on('addresschosen', (evt) => {
        geoCoder.getSource().clear();
        this.searchComplete.emit(ol.proj.toLonLat(evt.coordinate));
      });
    }
    this.snap = new ol.interaction.Snap({
      source: this.vectorLayer.getSource(),
    });
    // this.map.on('moveend', () => {
    //   if (this.detailPopup) {
    //     const newZoom = this.map.getView().getZoom();
    //     if (this.currentZoomLevel !== newZoom) {
    //       if (newZoom < 12) {          } else {
    //         // this.addMarkers(this.devices);
    //       }
    //       this.currentZoomLevel = newZoom;
    //     }
    //   }
    // });
    // ADD POPUP INTERACTION
    if (this.haveDetailPopup || this.haveMenuPopup) {
      this.mapPopup = new ol.Overlay({
        element: this.popup.nativeElement,
        positioning: !this.haveDetailPopup ? 'bottom-center' : 'top-left',
        id: 'map-popup',
      });
      // HANDLE POINTER MOVE EVENT
      this.map.on('pointermove', (evt) => {
        // ADD CURSOR POINTER CSS WHEN HOVER TO MARKER
        if (this.selectionType !== 'polygon' && !this.isHeatmapActive && this.haveDetailPopup) {
          const marker: any = this.map.forEachFeatureAtPixel(evt.pixel, (selectedFeature) => selectedFeature);
          const style = `width: ${this.width}; height: ${this.height}`;
          this.map.getTargetElement()
            .setAttribute('style', `${style}; cursor: ${marker && !this.isHoverPopupOpen ? 'pointer' : 'unset'}`);
        }
      });
    }
    this.map.on('click', (evt) => {
      // DETACH POPUP WHEN CLICK OUTSIDE
      if (this.markerHoverPopup && this.selectionType !== 'select') {
        this.map.removeOverlay(this.markerHoverPopup);
        this.detailPopup.nativeElement.hidden = true;
      }
      // ATTACH POPUP WHEN CLICK ON MARKER
      if (!this.isHeatmapActive && !this.activeDraw) {
        const marker: any = this.map.forEachFeatureAtPixel(evt.pixel, (selectedFeature) => selectedFeature);
        if (marker) {
          const coordinate = marker.getGeometry().getCoordinates();
          if (marker.values_.features.length === 1) {
            this.map.getView().animate({center: coordinate, duration: 1000});
            const parsedCoordinate = ol.proj.toLonLat(coordinate);
            this.hoveredMarker = this.devices
              .find(device => +device.latitude === parsedCoordinate[1] && +device.longitude === parsedCoordinate[0]);
            this.markerHoverPopup.setPosition(coordinate);
            this.map.addOverlay(this.markerHoverPopup);
            this.detailPopup.nativeElement.hidden = false;
          } else {
            const currentZoomPoint = this.map.getView().getZoom();
            if (currentZoomPoint < 19) {
              const zoomPoint = currentZoomPoint >= 16 ? currentZoomPoint + 1 : 16;
              this.map.getView().animate({center: coordinate, zoom: zoomPoint, duration: 1500});
            }
          }
        }
      }
      if (this.haveSearchLocation) {
        this.searchComplete.emit(ol.proj.toLonLat(evt.coordinate));
      }
    });
    if (this.detailPopup) {
      this.detailPopup.nativeElement.onmouseover = () => {
        this.isHoverPopupOpen = true;
      };
      this.detailPopup.nativeElement.onmouseout = () => {
        this.isHoverPopupOpen = false;
      };
    }
    setTimeout(() => this.map.updateSize(), 300);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (!this.isHeatmapActive) {
        // ADD DRAW INTERACTION
        if (this.haveDrawInteraction && this.draw) {
          this.map.addInteraction(this.draw);
          this.map.addOverlay(this.mapPopup);
          this.draw.setActive(this.selectionType === 'polygon');
          // ADD SNAP INTERACTION
          this.map.addInteraction(this.snap);
        }
      } else {
        this.map.removeOverlay(this.mapPopup);
        this.popup.nativeElement.hidden = true;
        this.map.removeOverlay(this.mapPopup);
        this.map.removeOverlay(this.markerHoverPopup);
        this.vectorSource.clear();
      }
    }
  }

  continueSelectDevices() {
    this.toggleDraw(this.selectionType === 'polygon');
    this.toggleSelect(this.selectionType === 'select');
    this.map.removeOverlay(this.mapPopup);
    this.popup.nativeElement.hidden = true;
  }

  cancelAction() {
    this.continueSelectDevices();
    this.selectedMarkers = [];
    this.cancelGroup.emit();
    this.vectorSource.clear();
  }

  handleMapTypeChange(type) {
    this.selectedMapType = type;
    this.mapTypes.forEach((mapType, index) => {
      this.mapLayers[index].setVisible(mapType.value === type);
    });
  }

  getStyle(feature: any, cache: any) {
    const size = feature.get('features').length;
    let style;
    if (!style) {
      if (size < 2) {
        const type = feature.get('features')[0].get('deviceType');
        const status = feature.get('features')[0].get('deviceStatus');
        if (!cache[`${type}-${status}`]) {
          const loadImage = url => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(img);
              img.onerror = () => reject(console.error(`load ${url} fail`));
              img.src = url;
            });
          };
          loadImage(`assets/images/map-icons/marker-${status}.png`).then((marker: any) => {
            loadImage(`assets/images/device-icons/${type}.png`).then(icon => {
              const canvas: any = document.createElement('canvas');
              canvas.width = marker.width;
              canvas.height = marker.height;
              canvas.getContext('2d').drawImage(marker, 0, 0, 45, 44);
              canvas.getContext('2d').drawImage(icon, 10, 10, 20, 20);
              cache[`${type}-${status}`] = canvas.toDataURL();
              style = new ol.style.Style({
                image: new ol.style.Icon(({
                  anchor: [0.5, 0.8],
                  src: canvas.toDataURL(),
                })),
              });
            });
          });
        }
        style = new ol.style.Style({
          image: new ol.style.Icon(({
            anchor: [0.5, 0.8],
            src: cache[`${type}-${status}`] ? cache[`${type}-${status}`] : `assets/images/map-icons/marker-unknown.png`,
          })),
        });
      } else {
        style = cache[size] = new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 0.8],
            src: `assets/images/map-icons/marker-unknown.png`,
          }),
          text: new ol.style.Text({
            text: size.toString(),
            fill: new ol.style.Fill({
              color: '#fff',
            }),
            scale: 1.8,
            offsetX: -4,
            offsetY: -15,
          }),
        });
      }
    }
    return [style];
  }

  // private addMarkers(devices: IDevice[]) {
  //   // CLEAR ALL PREVIOUS MARKER
  //   this.markerSource.clear();
  //   let markers = [];
  //   devices.forEach(device => {
  //     const marker = new ol.Feature({geometry: getGeoPoint(device.longitude, device.latitude)});
  //     const icon = new ol.Feature({geometry: getGeoPoint(device.longitude, device.latitude)});
  //     const anchorY = device.type && device.type.toLowerCase() === 'gateway' ? 1.3 : 1.3;
  //     marker.setStyle(createStyle(`assets/images/map-icons/marker-${device.stringStatus.toLowerCase()}.png`));
  //     icon.setStyle(createStyle(`assets/images/device-icons/${device.type && device.type.toLowerCase()}.png`, [0.65, anchorY]));
  //     markers = [...markers, icon, marker];
  //   });
  //   // ADD NEW MARKERS
  //   this.markerSource.addFeatures(markers);
  //   // INIT MARKER LAYOUT VECTOR ONLY ONE TIME
  //   if (!this.markerLayer) {
  //     this.markerLayer = new ol.layer.Vector({
  //       source: this.markerSource,
  //     });
  //     setTimeout(() => this.map.addLayer(this.markerLayer), 100);
  //   }
  // }

  private updateDrawType(type: 'Polygon' | 'LineString' = 'Polygon') {
    if (this.draw) {
      this.map.removeInteraction(this.draw);
    }
    this.draw = new ol.interaction.Draw({
      source: this.vectorLayer.getSource(),
      type,
    });
    this.draw.setActive(!!this.selectionType);
    // HANDLE DRAW EVENT END
    this.draw.on('drawend', (e) => {
      const polygon = e.feature.getGeometry();
      // const polygonCoordinate = polygon.getCoordinates();
      // const markers = this.markerLayer.getSource().getFeatures();
      const markers = this.clusterSource.getSource().getFeatures();
      // RESOLVE MARKERS THAT INSIDE THE POLYGON
      let selectedMarkers: any = markers.filter(marker => polygon.intersectsExtent(marker.getGeometry().getExtent()));
      selectedMarkers = [...selectedMarkers.map(markerFeature => {
        const markerCoordinate = ol.proj.toLonLat(markerFeature.getGeometry().getCoordinates());
        return this.getDeviceKeyByCoordinate(markerCoordinate);
      })];
      this.drawComplete.emit(selectedMarkers);
      // if (selectedMarkers.length > 0) {
      //   this.map.addOverlay(this.mapPopup);
      //   this.mapPopup.setPosition(polygonCoordinate[0][0]);
      //   this.popup.nativeElement.hidden = false;
      //   this.toggleDraw(false);
      // }
    });
    this.map.addInteraction(this.draw);
  }

  private triggerSelectInteraction() {
    this.map.removeOverlay(this.mapPopup);
    this.popup.nativeElement.hidden = true;
    // INIT SELECT INTERACTION
    this.select = new ol.interaction.Select({
      condition: ol.events.condition.click,
    });
    this.select.on('select', (evt) => {
      const selectedMarker = evt.selected[0];
      if (selectedMarker) {
        this.vectorSource.clear();
        this.selectedMarkers.push(selectedMarker.getGeometry().getCoordinates());
        const coordinate = ol.proj.toLonLat(selectedMarker.getGeometry().getCoordinates());
        this.vectorSource.addFeature(new ol.Feature({
          geometry: new ol.geom.LineString(this.selectedMarkers),
        }));
        // if (this.selectedMarkers.length > 1) {
        //   this.map.addOverlay(this.mapPopup);
        //   this.mapPopup.setPosition(selectedMarker.getGeometry().getCoordinates());
        //   this.popup.nativeElement.hidden = false;
        //   this.toggleSelect(false);
        // }
        this.drawComplete.emit([this.getDeviceKeyByCoordinate(coordinate)]);
      }
    });
    this.map.addInteraction(this.select);
  }

  private getDeviceKeyByCoordinate(coordinate): IDevice {
    const toDecimal = (value) => value.toFixed(12);
    return this.devices
      .find(device => toDecimal(+device.latitude) === toDecimal(coordinate[1])
        && toDecimal(+device.longitude) === toDecimal(coordinate[0]));
  }

  private toggleDraw(value: boolean) {
    if (this.draw) {
      this.draw.setActive(value);
    }
  }

  private toggleSelect(value: boolean) {
    if (this.select) {
      this.select.setActive(value);
    }
  }

  ngOnDestroy(): void {
    this.olMap.nativeElement.parentNode.removeChild(this.olMap.nativeElement);
  }
}
