import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  isMobile = false;

  constructor() {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    window.addEventListener('resize', () => this.isMobile = window.innerWidth <= 768);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', () => this.isMobile = false);
  }
}
