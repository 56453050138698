import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-wisx-card-simple-detail',
  templateUrl: './wisx-card-simple-detail.component.html',
  styleUrls: ['./wisx-card-simple-detail.component.scss']
})
export class WisxCardSimpleDetailComponent implements OnInit {

  @Input() title: string;
  @Input() haveRefresh = false;
  @Input() refresh: () => any;

  constructor() { }

  ngOnInit() {
  }

}
