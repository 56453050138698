import { ILocaleLang } from './models';

export const LOCAL_STORAGE_KEY: {[key: string]: string} = {
  authorization: 'AUTHORIZATION',
  dashSetting: 'DASHBOARD'
};

export const FOOTER_TEXT = 'Copyright © 2019 ST Electronics (Info-Comm Systems) Pte Ltd';

export const LOCALE_LANGS: ILocaleLang[] = [
  {
    name: 'English',
    flag: 'england',
    value: 'en'
  },
  {
    name: 'China',
    flag: 'china',
    value: 'cn'
  },
  {
    name: 'Indonesia',
    flag: 'indo',
    value: 'indo'
  }
];
