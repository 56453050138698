import { Component, Input, OnInit } from '@angular/core';

const MOCK_DEVICES: any = [
  {
    name: '0000ABCD1234',
    icon: 'far fa-broadcast-tower',
    type: 'gateway',
  },
  {
    name: '7276F0B031B42',
    icon: 'far fa-draw-circle',
    type: 'sensor',
  },
  {
    name: 'M2M_NODE_01',
    icon: 'far fa-project-diagram',
    type: 'node',
  },
  {
    name: 'LPTEST01-IoTGW-4G',
    icon: 'far fa-draw-circle',
    type: 'sensor',
  },
  {
    name: 'MQTTSensorNode',
    icon: 'far fa-project-diagram',
    type: 'node',
  },
  {
    name: 'LaaP_SGW01',
    icon: 'far fa-hdd',
    type: 'fieldDevice',
  },
  {
    name: 'SIU-LP-SIU01',
    icon: 'far fa-hdd',
    type: 'fieldDevice',
  },
];

@Component({
  selector: 'app-group-devices-form',
  templateUrl: './group-devices-form.component.html',
  styleUrls: ['./group-devices-form.component.scss']
})
export class GroupDevicesFormComponent implements OnInit {

  @Input() devices: any = MOCK_DEVICES;
  @Input() onlySearch = false;

  gateway: any = [];
  sensor: any = [];
  node: any = [];
  fieldDevice: any = [];

  constructor() { }

  ngOnInit() { }

  returnItemToDevices(item: any) {
    const currentSlotDevices = [...this[item.type]];
    const index = this[item.type].findIndex(device => device.name === item.name);
    if (index > -1) {
      this.devices.push(item);
      this[item.type] = [
        ...currentSlotDevices.slice(0, index),
        ...currentSlotDevices.slice(index + 1),
      ];
    }
  }

  handleSelectItem(item: any) {
    const index = this.devices.findIndex(device => device.name === item.name);
    if (index > -1) {
      const currentDevices = [...this.devices];
      this[item.type].push(item);
      this.devices = [
        ...currentDevices.slice(0, index),
        ...currentDevices.slice(index + 1),
      ];
    }
  }
}
