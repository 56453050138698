export function createStyle(src, anchor = [0.5, 0.8], img?) {
    return new ol.style.Style({
      image: new ol.style.Icon(({
        anchor,
        crossOrigin: 'anonymous',
        src,
        img,
        imgSize: img ? [img.width, img.height] : undefined
      })),
    });
}

export function getGeoPoint(long, lat) {
  return new ol.geom.Point(ol.proj.fromLonLat([+long, +lat]));
}
