import { Component, Input, OnInit } from '@angular/core';
import { ICommonListItem } from '../../../../shared/models';

@Component({
  selector: 'app-wisx-listbox',
  templateUrl: './wisx-listbox.component.html',
  styleUrls: ['./wisx-listbox.component.scss']
})
export class WisxListboxComponent implements OnInit {

  @Input() leftBoxTitle = 'Sensors to be Subscribed';
  @Input() rightBoxTitle = 'Sensors Subscribed';
  @Input('listBoxItem') set updateLeftBoxItems(value: ICommonListItem[]) {
    this.leftSideList = value || [];
    this.tempLeftSideList = value || [];
  }
  @Input('lockedItem') set updateRightBoxItems(value: ICommonListItem[]) {
    this.rightSideList = value || [];
    this.tempRightSideList = value || [];
  }
  @Input() lockSwap = false;

  leftSideList: ICommonListItem[] = [
    {
      label: 'Lora 4534',
      value: 'lora4534',
    },
    {
      label: 'Lora 4535',
      value: 'lora4535',
    },
    {
      label: 'Lora 4536',
      value: 'lora4536',
    },
    {
      label: 'Lora 4537',
      value: 'lora4537',
    }
  ];
  rightSideList: ICommonListItem[] = [];

  tempLeftSideList: ICommonListItem[] = this.leftSideList;
  tempRightSideList: ICommonListItem[] = [];
  selectedItem: ICommonListItem;

  constructor() { }

  ngOnInit() { }

  handleItemClick(side, item) {
    if (this.lockSwap) {
      this.selectedItem = item;
    } else {
      if (side === 'l') {
        this.rightSideList.push(item);
        this.leftSideList = [...this.leftSideList.filter(element => element.value !== item.value)];
        this.tempRightSideList = [...this.rightSideList];
      } else {
        this.leftSideList.push(item);
        this.rightSideList = [...this.rightSideList.filter(element => element.value !== item.value)];
        this.tempLeftSideList = [...this.leftSideList];
      }
    }
  }

  handleSearchChange(event, side) {
    const tempArr = [...this.getSearchList(side)];
    if (side === 'l') {
      this.leftSideList = [...tempArr.filter(item => item.label.includes(event.target.value))];
    } else {
      this.rightSideList = [...tempArr.filter(item => item.label.includes(event.target.value))];
    }
  }

  getSearchList(side: string) {
    if (side === 'l') {
      return this.tempLeftSideList.filter(item => this.rightSideList.findIndex(rItem => rItem.value === item.value) === -1);
    } else {
      return this.tempRightSideList.filter(item => this.leftSideList.findIndex(lItem => lItem.value === item.value) === -1);
    }
  }

  onSwapBtnClick() {
    if (this.rightSideList.length > 0) {
      this.leftSideList = this.tempLeftSideList = [...this.leftSideList, ...this.rightSideList];
      this.rightSideList = this.tempRightSideList = [];
    } else {
      this.rightSideList = this.tempRightSideList = [...this.leftSideList, ...this.rightSideList];
      this.leftSideList = this.tempLeftSideList = [];
    }
  }
}
