import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-wixs-form-confirm-step',
  templateUrl: './wixs-form-confirm-step.component.html',
  styleUrls: ['./wixs-form-confirm-step.component.scss']
})
export class WixsFormConfirmStepComponent implements OnInit {

  @Input() title: string;
  @Input() isFinish = false;
  @Input() isError = false;

  constructor() { }

  ngOnInit() {
  }

}
