import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { ILoginCommunicate } from '../shared/models';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenGuard implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.loginReq.pipe(first()).toPromise().then(
      (loginInfo: ILoginCommunicate) => {
        if (loginInfo.isLoggedIn) {
          return true;
        }
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
      });
  }
}
