import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-wisx-modal',
  templateUrl: './wisx-modal.component.html',
  styleUrls: ['./wisx-modal.component.scss']
})
export class WisxModalComponent implements OnInit {

  @Input() visible = true;
  @Input() title = 'Confirm Delete';
  @Input() confirmTitle: string;
  @Input() okBtnText = 'COMMON.CONFIRM';
  @Input() width: number;
  @Input() customFooter: TemplateRef<any>;

  @Output() closePopup = new EventEmitter<any>();
  @Output() okClick = new EventEmitter<any>();
  @Output() cancelClick = new EventEmitter<any>();
  @Output() afterClose = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
