import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICommonListItem } from '../../../../shared/models';

@Component({
  selector: 'app-wisx-wizard-form',
  templateUrl: './wisx-wizard-form.component.html',
  styleUrls: ['./wisx-wizard-form.component.scss']
})
export class WisxWizardFormComponent implements OnInit {

  @Input() step = 0;
  @Input() spacing = 200;
  @Input() isComplete = false;
  @Input() steps: ICommonListItem[] = [
    {
      value: 0,
      label: 'Basic Detail',
    },
    {
      value: 1,
      label: 'Location Detail',
    },
    {
      value: 2,
      label: 'Confirmation',
    },
  ];

  @Output() next = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

}
