import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICommonListItem, IDashboardStatCard } from '../../../../shared/models';

@Component({
  selector: 'app-setting-menu',
  templateUrl: './setting-menu.component.html',
  styleUrls: ['./setting-menu.component.scss']
})
export class SettingMenuComponent implements OnInit {

  @Input() title: string;
  @Input() actionText: string;
  @Input('menuItems') set items(val: ICommonListItem[] | any) {
    this.menuItems = [...val];
  }
  @Input() showFooter = true;

  @Output() closePopover = new EventEmitter<void>();
  @Output() resetData = new EventEmitter<void>();
  @Output() saveData = new EventEmitter<void>();
  @Output() menuValueChange = new EventEmitter<ICommonListItem[] | any>();

  menuItems: ICommonListItem[] | any = [];

  constructor() { }

  ngOnInit() { }

  handleSwitchChange(value: any, selectedItem: any) {
    const menu = [...this.menuItems];
    const index = menu.findIndex(item => item.label === selectedItem.label);
    if (index > -1) {
      menu[index].value = value;
    }
    this.menuValueChange.emit([...menu]);
  }

}
