import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthenGuard } from './guard/authen.guard';
import { CustomPreloadStrategyService } from './config/custom-preload-strategy.service';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';

const lazyRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './modules/lazy-loaded/dashboard/dashboard.module#DashboardModule',
    data: {preload: true, title: 'TITLE.DASHBOARD'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'map',
    loadChildren: './modules/lazy-loaded/map/map.module#MapModule',
    data: {preload: true, title: 'COMMON.MAP'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'devices',
    loadChildren: './modules/lazy-loaded/devices/devices.module#DevicesModule',
    data: {preload: true, title: 'COMMON.DEVICES'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'groups',
    loadChildren: './modules/lazy-loaded/groups/groups.module#GroupsModule',
    data: {preload: true, title: 'COMMON.GROUPS'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'alerts',
    loadChildren: './modules/lazy-loaded/alerts/alerts.module#AlertsModule',
    data: {preload: true, title: 'COMMON.ALERTS'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'data-loggers',
    loadChildren: './modules/lazy-loaded/data-logger/data-logger.module#DataLoggerModule',
    data: {preload: true, title: 'TITLE.DATA-LOGGER'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'user-management',
    loadChildren: './modules/lazy-loaded/user-management/user-management.module#UserManagementModule',
    data: {preload: true, title: 'TITLE.USER-MANAGEMENT'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'maintenance',
    loadChildren: './modules/lazy-loaded/maintenance/maintenance.module#MaintenanceModule',
    data: {preload: true, title: 'COMMON.MAINTENANCE'},
    canActivate: [AuthenGuard],
  },
  {
    path: '',
    loadChildren: './modules/lazy-loaded/applications/applications.module#ApplicationsModule',
    data: {preload: true, title: 'COMMON.APPLICATIONS'},
    canActivate: [AuthenGuard],
  },
  {
    path: 'settings',
    loadChildren: './modules/lazy-loaded/settings/settings.module#SettingsModule',
    data: {preload: true, title: 'COMMON.SETTINGS'},
    canActivate: [AuthenGuard],
  },
  {
    path: '',
    loadChildren: './modules/lazy-loaded/profile/profile.module#ProfileModule',
    data: {preload: true, title: 'COMMON.PROFILE'},
    canActivate: [AuthenGuard],
  },
];

const mainRoute: Routes = [
  ...lazyRoutes,
  {
    path: 'under-construction',
    component: UnderConstructionComponent,
  },
  {
    path: '',
    redirectTo: '/dashboard', pathMatch: 'full',
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(mainRoute, {
      preloadingStrategy: CustomPreloadStrategyService,
    })
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
