export interface ISidebarItem {
  title: string;
  url: string;
  icon?: string;
  children?: ISidebarItem[];
}

const SIDEBAR_ITEMS: ISidebarItem[] = [
  {
    title: 'TITLE.DASHBOARD',
    url: '/dashboard',
    icon: 'fal fa-chalkboard-teacher',
  },
  {
    title: 'COMMON.DEVICES',
    url: '/devices',
    icon: 'fal fa-satellite-dish',
    children: [
      {
        title: 'TITLE.FIELD-DEVICES',
        url: '/devices/field-devices',
      },
      {
        title: 'COMMON.SENSORS',
        url: '/devices/sensors',
      },
      {
        title: 'TITLE.SENSOR-NODES',
        url: '/devices/sensor-nodes',
      },
      {
        title: 'TITLE.SENSOR-GATEWAYS',
        url: '/devices/sensor-gateways',
      },
    ],
  },
  {
    title: 'COMMON.GROUPS',
    url: '/groups',
    icon: 'fal fa-ball-pile',
    children: [
      {
        title: 'TITLE.SENSOR-GROUPS',
        url: '/groups/sensor-groups',
      },
      {
        title: 'TITLE.MULTICAST-GROUPS',
        url: '/groups/multicast-groups',
      },
    ],
  },
  {
    title: 'COMMON.ALERTS',
    url: '/alerts',
    icon: 'fal fa-bell',
    children: [
      {
        title: 'TITLE.EVENTS-ALERT',
        url: '/alerts/events-alert',
      },
      {
        title: 'TITLE.ALARMS-ALERT',
        url: '/alerts/alarms-alert',
      },
    ],
  },
  {
    title: 'TITLE.DATA-LOGGER',
    url: '/data-loggers',
    icon: 'fal fa-file-chart-line',
    children: [
      {
        title: 'TITLE.DDS-DATA-LOGGER',
        url: '/data-loggers/dds',
      },
      {
        title: 'TITLE.GGNS-DATA-LOGGER',
        url: '/data-loggers/ggns',
      },
      {
        title: 'TITLE.LORA-DATA-LOGGER',
        url: '/data-loggers/lora',
      },
      {
        title: 'TITLE.MQTT-DATA-LOGGER',
        url: '/data-loggers/mqtt',
      },
    ],
  },
  {
    title: 'TITLE.USER-MANAGEMENT',
    url: '/user-management',
    icon: 'fal fa-users',
    children: [
      {
        title: 'COMMON.USERS',
        url: '/user-management/users',
      },
      {
        title: 'TITLE.USER-GROUPS',
        url: '/user-management/user-groups',
      },
      {
        title: 'COMMON.TENANTS',
        url: '/under-construction',
      },
    ],
  },
  {
    title: 'COMMON.MAINTENANCE',
    url: '/maintenance',
    icon: 'fal fa-toolbox',
    children: [
      {
        title: 'COMMON.FIRMWARE',
        url: '/maintenance/firmware',
      },
      {
        title: 'TITLE.SYS-CONFIG',
        url: '/under-construction',
      },
    ],
  },
  {
    title: 'COMMON.APPLICATIONS',
    url: '/applications',
    icon: 'fal fa-ethernet',
  },
  {
    title: 'COMMON.SETTINGS',
    url: '/settings',
    icon: 'fal fa-cog',
    children: [
      {
        title: 'COMMON.CERTIFICATES',
        url: '/settings/certificates',
      },
      {
        title: 'TITLE.FD-CATEGORY',
        url: '/settings/fd-category',
      },
      {
        title: 'TITLE.SENSOR-TYPE',
        url: '/settings/sensor-type',
      },
    ],
  },
];

export default SIDEBAR_ITEMS;
