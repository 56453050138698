import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RoutesRecognized } from '@angular/router';

import { ILocaleLang, ILoginCommunicate } from '../../shared/models';
import { FOOTER_TEXT, LOCALE_LANGS } from '../../shared/constant';
import { AuthService } from '../../services/auth/auth.service';
import SIDEBAR_ITEMS, { ISidebarItem } from './sidebar-item';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  sidebarItems: ISidebarItem[] = SIDEBAR_ITEMS;
  isCollapsed = false;
  currentRoute = '/';
  isUserLoggedIn = false;
  pageTitle = '';
  languages: ILocaleLang[] = LOCALE_LANGS;
  currentLang: ILocaleLang = LOCALE_LANGS[0];
  footerText: string = FOOTER_TEXT;
  isChangeTheme = false;

  private subscription: Subscription;

  constructor(private auth: AuthService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private nzI18n: NzI18nService) {
    this.currentRoute = this.router.url;
    this.subscription = this.auth.loginReq.subscribe((loginInfo: ILoginCommunicate) => this.isUserLoggedIn = loginInfo.isLoggedIn);
    this.languages = [...this.languages.filter(lang => lang.value !== this.currentLang.value)];
  }

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof RoutesRecognized) {
        this.currentRoute = event.url;
      }
      if (event instanceof NavigationEnd) {
        let route = this.activatedRoute.firstChild;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.pageTitle = route.snapshot.data.title;
      }
    });
  }

  handleLangChange(selectedLang) {
    this.languages = [...LOCALE_LANGS.filter(lang => lang.value !== selectedLang.value)];
    this.currentLang = selectedLang;
    this.translate.use(selectedLang.value);
    this.nzI18n.setLocale(selectedLang.value === 'cn' ? zh_CN : en_US);
  }

  onLogoutBtnClick() {
    this.auth.logout();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
