import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../';
import { ILoginCommunicate, IUserCredential } from '../../shared/models';
import { LOCAL_STORAGE_KEY } from '../../shared/constant';

const asyncFake = () =>
  new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, 1500),
  );

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private $loginReq: BehaviorSubject<ILoginCommunicate> = new BehaviorSubject({ isLoggedIn: false, isRequesting: false });
  public loginReq: Observable<ILoginCommunicate> = this.$loginReq.asObservable();

  constructor(private storage: StorageService,
              private router: Router) {
    if (this.storage.authorizationInfo) {
      this.$loginReq.next({ isRequesting: false, isLoggedIn: true });
    }
  }

  public async login(credential: IUserCredential, redirectUrl?: string) {
    this.$loginReq.next({ isRequesting: true, isLoggedIn: false });
    if (credential.username === 'admin' && credential.password === 'admin') {
      await asyncFake();
      this.storage.setItem(LOCAL_STORAGE_KEY.authorization, 'loginSuccess');
      this.$loginReq.next({ isLoggedIn: true, isRequesting: false });
      this.router.navigate([redirectUrl ? redirectUrl : '/dashboard']);
    } else {
    }
  }

  public logout() {
    this.storage.clearAllStorage();
    this.$loginReq.next({ isRequesting: false, isLoggedIn: false });
    this.router.navigate(['/login']);
  }
}
